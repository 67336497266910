<template>
  <div>
    <Title title="Flightscope Aviation Documentation" />

    <div class="accordion">
      <AccordionItem class="accordion-item">
        <template v-slot:heading>Administration</template>
        <template v-slot:description>
          <DocumentList :documentListItems="administrationItems" />
        </template>
      </AccordionItem>
    </div>
    <div class="accordion">
      <AccordionItem class="accordion-item">
        <template v-slot:heading>Aircraft Checklists</template>
        <template v-slot:description>
          <DocumentList :documentListItems="checklistListItems" />
        </template>
      </AccordionItem>
    </div>
    <div class="accordion">
      <AccordionItem class="accordion-item">
        <template v-slot:heading>Aircraft Circuit Diagrams</template>
        <template v-slot:description>
          <DocumentList :documentListItems="circuitDiagramsListItems" />
        </template>
      </AccordionItem>
    </div>
    <div class="accordion">
      <AccordionItem class="accordion-item">
        <template v-slot:heading>Flight Briefing</template>
        <template v-slot:description>
          <DocumentList :documentListItems="flightBriefingListItems" />
        </template>
      </AccordionItem>
    </div>
  </div>
</template>

<script>
import Title from '@/components/Title.vue'
import DocumentList from '@/components/DocumentList.vue'
import AccordionItem from '@/components/AccordionItem.vue'

export default {
  components: {
    Title,
    DocumentList,
    AccordionItem
  },
  data () {
    return {
      administrationItems: [
        { router: false, documentLink: 'https://flightscope.gitlab.io/training/private-hire/private-hire.pdf', imagePath: require('@/assets/images/private_hire/private-hire_page1.pdf.png-150.png'), documentName: 'Private Hire Questionnaire' }
      ],
      checklistListItems: [
        { router: false, documentLink: 'https://flightscope.gitlab.io/aircraft/checklist/eurofox.pdf', imagePath: require('@/assets/images/aircraft_checklist/eurofox_page1.pdf.png-150.png'), documentName: 'Eurofox 3K Quick Reference Checklist' },
        { router: false, documentLink: 'https://flightscope.gitlab.io/aircraft/checklist/cessna-182p.pdf', imagePath: require('@/assets/images/aircraft_checklist/cessna-182p_page1.pdf.png-150.png'), documentName: 'Cessna 182P Quick Reference Checklist' },
        { router: false, documentLink: 'https://flightscope.gitlab.io/aircraft/checklist/cessna-172r.pdf', imagePath: require('@/assets/images/aircraft_checklist/cessna-172r_page1.pdf.png-150.png'), documentName: 'Cessna 172R Quick Reference Checklist' },
        { router: false, documentLink: 'https://flightscope.gitlab.io/aircraft/checklist/cessna-152.pdf', imagePath: require('@/assets/images/aircraft_checklist/cessna-152_page1.pdf.png-150.png'), documentName: 'Cessna 152 Quick Reference Checklist' },
        { router: false, documentLink: 'https://flightscope.gitlab.io/aircraft/checklist/aquila-a210.pdf', imagePath: require('@/assets/images/aircraft_checklist/aquila-a210_page1.pdf.png-150.png'), documentName: 'Aquila A210 Quick Reference Checklist' },
        { router: false, documentLink: 'https://flightscope.gitlab.io/aircraft/checklist/super-decathlon.pdf', imagePath: require('@/assets/images/aircraft_checklist/super-decathlon_page1.pdf.png-150.png'), documentName: 'Super Decathlon Quick Reference Checklist' },
        { router: false, documentLink: 'https://flightscope.gitlab.io/aircraft/checklist/aircraft-admin.pdf', imagePath: require('@/assets/images/aircraft_checklist/aircraft-admin_page1.pdf.png-150.png'), documentName: 'General Aircraft Administration Checklist' }
      ],
      circuitDiagramsListItems: this.$circuitDiagramsListItems,
      flightBriefingListItems: [
        { router: false, documentLink: 'https://flightscope.gitlab.io/aircraft/checklist/departure-briefing.pdf', imagePath: require('@/assets/images/aircraft_checklist/departure-briefing_page1.pdf.png-150.png'), documentName: 'Safety and Departure Briefing' }
      ]
    }
  }
}
</script>
