<template>
  <div>
    <ul class="document-list">
      <li class="list-item" v-for="(item, index) in documentListItems" :key="index">
        <a :class="{ center: !item.icon && !item.imagePath, leftaligned: leftaligned, minheight2: minheight2, xlargefont: xlargefont  }" v-if="!item.router" :href="item.documentLink">
          <img v-if="item.imagePath" class="document-image" :src="item.imagePath" :alt="item.documentName" />
          <font-awesome-icon v-if="item.icon" class="icon" :icon="{ prefix: item.iconGroup, iconName: item.icon }" />
          <span>{{ item.documentName }}</span>
        </a>
        <router-link :class="{ center: !item.icon && !item.imagePath }" v-else :to="item.documentLink">
          <img v-if="item.imagePath" class="document-image" :src="item.imagePath" :alt="item.documentName" />
          <font-awesome-icon v-if="item.icon" class="icon" :icon="{ prefix: item.iconGroup, iconName: item.icon }" />
          <span>{{ item.documentName }}</span>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: ['documentListItems', 'leftaligned', 'minheight2', 'xlargefont']
}
</script>

<style lang="scss" scoped>
@use '@/styles/_presets.scss';
@import '@/styles/_colors.scss';

.document-list {
  width: 80vw;
  margin: auto;
  padding: 0;
}

.list-item {
  list-style-type: none;
  border: 1px solid $primary;
  break-inside: avoid-column;
  margin-left: -1px;
  margin-top: -1px;
  transition: background-color 0.3s ease-in-out;
  background-color: white;

  &:hover {
    background-color: $secondary;
  }

  a {
    @extend h2;
    display: flex;
    @media screen and (max-width: 600px) { flex-direction: column; justify-content: center; }
    justify-content: space-between;
    &.center {
      justify-content: center;
    }

    &.leftaligned {
      justify-content: start !important;
    }

    &.minheight2 {
      min-height: 2em !important;
    }

    &.xlargefont {
      font-size: x-large !important;
    }

    align-items: center;
    padding: 1vh;
    text-decoration: none;
    flex-wrap: wrap;
    min-height: 5em;
  }
}

.icon {
  font-size: 2em;
  padding: 2vh;
}

.document-image {
  height: 5em;
  width: auto;
}
</style>
