<template>
  <div class="accordion-item">
    <button class="accordion" @click="accordionClick($event)"><slot name="heading" /><font-awesome-icon class="arrow-down" :icon="{ prefix: 'fa', iconName: 'caret-right' }" /></button>
    <div class="panel">
      <p><slot name="description" /></p>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    accordionClick (event) {
      event.target.classList.toggle('active')
      const panel = event.target.nextElementSibling
      if (panel.style.maxHeight) {
        panel.style.maxHeight = null
      } else {
        panel.style.maxHeight = panel.scrollHeight + 'px'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@use '@/styles/_presets.scss';
@import '@/styles/_colors.scss';

/* Style the buttons that are used to open and close the accordion panel */
.accordion {
  background-color: white;
  color: $primary;
  cursor: pointer;
  padding: 1em;
  width: 100%;
  overflow: scroll;
  text-align: left;
  border: none;
  outline: none;
  transition: 0.4s;
  font-size: calc(1em);
  display: flex;
  justify-content: space-between;
  font-family: Raleway, Helvetica, Arial, sans-serif;
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
.active, .accordion:hover {
  background-color: $secondary;
}

.active .arrow-down {
  transform: rotate(90deg);
}

/* Style the accordion panel. Note: hidden by default */
.panel {
  padding: 0 1vw;
  background-color: white;
  max-height: 0;
  text-align: left;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
}

.arrow-down {
  font-size: 2em;
  transition: transform 0.3s ease-in-out;
}

.accordion-item {
  border-bottom: 1px solid $secondary-hover;
  &:first-child {
    border-top: 1px solid $secondary-hover;
  }
}
</style>
